<template>
    <div class="classSearchWrap">
      <!-- <div class="classWrap">
        <LevelsMenu  @change="queryProduct"></LevelsMenu>
        </div> -->
        <div class="adress">
             <div class="content-top">
            <ul>

              <li>

                <em>地&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;区：</em>
                <em>全部</em>
                <div  v-for="(item4,index) in AdressClassList" :key="index">
                  <span @click="selectClass(item4.adcode,index)" :class="activeClass == index ? 'active' : ''" :key="index">
                   {{item4.NAME}}
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="section">
                <div class="recommend-list-wrap" v-for="(item1,i1) in ShopListContent" :key="i1">
                        <li class="recommend-list" :class="[ !item1.productList.length=='0' ? 'recommend-list' : 'recommend-list2' ]" >
                        <div class="shopAd">
                            <div class="shopAd-content">
                                <div class="shopAd-content-left">
                                    <img :src="item1.logoUrl | imagePath" alt="">
                                </div>
                                <div class="shopAd-content-right">
                                    <div class="opens">
                                    <span v-if="item1.sellerRank==3">v3</span>
                                    <span v-if="item1.sellerRank==2">v2</span>
                                    <span v-if="item1.sellerRank==1">v1</span>
                                    <span>
                                        <img src="../../assets/icon/ying.png" alt="">
                                    </span>
                                    <span>
                                        <img src="../../assets/icon/renzheng.png" alt="" v-if="item1.isPay">
                                    </span>
                                    <span>
                                        <img src="../../assets/icon/location.png" alt="">
                                    </span>
                                    <span>
                                        {{item1.address}}
                                    </span>
                                    <span>
                                        <img src="../../assets/icon/shutiao.png" alt="">
                                    </span>
                                    <!-- <span>
                                        在售产品：{{item1.storeProductCount}}
                                    </span> -->
                                    <span>
                                        成交量：{{item1.salesVolume}}
                                    </span>
                                    <!-- <span>
                                        服务案例：{{item1.storeProductCaseCount}}
                                    </span> -->
                                    <button type="button" class="shopAd-btn" @click="goshop(item1.storeId)">进入店铺</button>
                                    </div>
                                    <div class="server">
                                        <div class="server-title">
                                        擅长服务：
                                        </div>
                                        <div class="server-list">
                                        <span v-for="(item2,i2) in item1.serverList" :key="i2">
                                            {{item2}}
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- <button type="button" class="shopAd-btn" @click="goshop(item1.storeId)">进入店铺</button> -->
                        </div>

                        <div class="productWrap">
                            <div class="productWrap-top">
                                <div class="productWrap-top-content" v-if="item1.productList.length!=0">
                                    <!-- {{item1.productList}} -->
                                    <dl v-for="(item3,i3) in item1.productList" :key="i3">
                                        <dt>
                                        <img :src="item3.productPic | imagePath" alt="">
                                        </dt>
                                        <dd>
                                        <p>{{item3.name}}</p>
                                        <p>
                                            <!-- <span>参考价：</span> -->
                                            <span>￥{{item3.price}} </span>
                                            <span>起</span>
                                        </p>
                                        <div @click="dialogVisible1 = true;productShow(item3.productPic,item3.name,item3.price,item3.productId,item3.storeId)" >快速下单</div>
                                        </dd>
                                    </dl>


                                </div>
                                <div v-if="item1.productList.length==0" class="oneKeywrap">
                                    商家暂未选定产品出售，您可以直接联系商家沟通业务并点击
                                    “
                                    <span class="oneKey">一键支付</span>
                                    ”
                                    快速生成订单 ~

                                </div>
                                <div class="productBtnWrap">
                                  <button type="button" class="shopAd-btn" @click="dialogVisible = true;phone(item1.storeId)">联系商家</button>
                                    <button type="button" class="shopAd-btn" @click="dialogVisible2 = true;oneKeyShow(item1.storeName,item1.storeId)">一键支付</button>
                                </div>
                            </div>

                            <div class="otherwrap" v-if="item1.productList.length!=0">
                                <span>查看本店其他更多热售产品  >></span>

                            </div>
                        </div>
                        </li>
                 </div>

             <!-- <div class="section-right">
                <div>推荐优质商家</div>
                <div class="merchant-list">
                  <dl v-for="(item5,i5) in MerchantList" :key="i5">
                    <dt>
                      <img :src="item5.logoUrl | imagePath" alt="">
                    </dt>
                    <dd>
                      <div>
                        <span>{{item5.storeName}}</span>
                        <span v-if="item5.ispay">v3</span>
                        <span v-else-if="item5.qiye=='1'">v2</span>
                        <span v-else>v1</span>
                      </div>
                      <div>
                        <img src="../../assets/icon/location.png" alt="">
                        <span>鼓楼区</span>
                      </div>
                    </dd>
                  </dl>
                </div>
             </div> -->


        </div>


        <div class="quickOrder">
              <el-dialog title="" :visible.sync="dialogVisible">
                <div class="popTop">
                  <p class="storeName">{{store.storeName}}</p>
                <div class="storeMobile">
                  <div>
                    <span>
                      <!-- <img src="../../../assets/image/phone.png" alt="" align="middle"> -->
                    </span>
                    <span>{{store.mobile}}</span>
                  </div>
                  <div class="streFooter">
                    注：为了您的交易安全，请走孔雀有礼线上交易如遇可疑商家，可及时向平台投诉举报！
                  </div>
                </div>
                </div>
              </el-dialog>
            </div>

            <div class="quickOrder1">
              <el-dialog title="" :visible.sync="dialogVisible1">
                  <div class="popWrap">
                    <div class="titles">
                      <p>快速下单</p>
                      <p>您</p>
                      <p>已选定产品</p>
                      <p>且已经与商家沟通好价格，直接付款</p>
                      <p >可快速生成订单</p>
                    </div>
                    <div class="productPop">
                        <p>我需要：</p>
                        <div class="product-img">
                          <img :src="productObj.img | imagePath" alt="">
                        </div>
                        <div>
                          <p>{{productObj.name}}</p>
                          <div>
                            <span>参考价：</span>
                            <span>￥{{productObj.price}}</span>&nbsp;
                            <span>起</span>

                          </div>
                        </div>
                    </div>

                    <el-form ref="form" :model="form">
                      <el-form-item label=" 订单金额：" prop="consignee">
                        <el-input v-model="form.price" placeholder="请输入与商家已沟通好的价格（元）"></el-input>
                      </el-form-item>
                      <el-form-item label="订单备注：" prop="mobile">
                        <el-input v-model="form.info" placeholder="输入您对本订单的制作/配送/开票等要求说明（选填）" ></el-input>
                      </el-form-item>


                      <el-form-item class="save">
                        <el-button class="details" type="primary" @click="onSubmit">立即提交</el-button>
                      </el-form-item>
                    </el-form>
                  </div>
              </el-dialog>
            </div>

            <div class="quickOrder2">
              <el-dialog title="" :visible.sync="dialogVisible2">
                <div class="popWrap">
                  <div class="titles">
                    <p>一键下单</p>
                    <p>您已经与商家沟通好价格，直接付款</p>
                    <p>可快速生成订单</p>
                  </div>

                  <el-form ref="form" :model="form">
                    <el-form-item label=" 我需要：" prop="consignee">
                      <!-- <el-input v-model="form.requirementDesc" placeholder=""></el-input> -->
                      <el-input
                          type="textarea"
                          placeholder="简要说明您需要做的业务（包括物料、数量、工艺、服务要求等）"
                          v-model="form.info">
                      </el-input>
                    </el-form-item>
                    <el-form-item label="订单金额：" prop="mobile">
                      <el-input v-model="form.price" placeholder="请输入与商家已沟通好的价格（元）" maxlength="11" ></el-input>
                    </el-form-item>

                    <div class="protocol">
                        <el-checkbox v-model="checked" @change="checkedAll">
                          <span>我已阅读并同意&nbsp;</span>
                        </el-checkbox>
                        <span class="protocol-word">《孔雀有礼使用协议》</span>
                    </div>

                    <el-form-item class="save" v-if="isDisable">
                      <el-button class="details" type="primary"  disabled >立即提交</el-button>
                    </el-form-item>
                    <el-form-item class="save"  v-if="isSave" >
                      <el-button class="details" type="primary" @click="onSubmit1">立即提交</el-button>
                    </el-form-item>
                  </el-form>

                </div>
            </el-dialog>
            </div>
    </div>
</template>

<script>
import LevelsMenu from '@/components/common/levelsMenu'
export default {
    components: {  LevelsMenu},
    name:"classSearch",
    data(){
        return{
             isSave:true, //保存可用
      isDisable:false, //保存可用
      checked: true,

      //快速下单信息,一键支付信息
      form: {
        info: '', //备注信息
        price:"",
      },

      activeName: 'first', //tab默认为第一个
      sum:"会场庆典",
      sum2:"会场庆典",
      sum3:"会场庆典",
      recommendList:[], //孔雀推荐一级分类
      ShopListContent:[], //分类搜索店铺列表内容
     param:{ //点击分类传递的参数
        // lat:JSON.parse(localStorage.getItem("lng")),
        // lon:JSON.parse(localStorage.getItem("lat")),
        adcode:JSON.parse(localStorage.getItem("adcode")),
        name:this.$route.query.val, //用户搜索到的关键字
         state:"1", //产品搜索标识
         sqlState:"asc", //默认升序成交量
         page:"0",
         rows:"10"
        // cateId:this.$route.params.id, //任意分类的id,默认为点击进来分类的id
      },
      dialogVisible: false, //电话弹窗显示与否
      store: {}, //保存弹窗信息的对象
      dialogVisible: false, //联系商家显示与否
      dialogVisible1: false, //快速下单弹窗显示与否
      dialogVisible2: false, //一键支付弹窗显示与否
      productObj:{ //点击快速下单展示数据
        img:"",
        name:"",
        price:"",
        id:"",
        storeId:""
      },
      cityName:JSON.parse(localStorage.getItem("city")), //当前城市
      AdressClassList:[], //保存区的数组
      activeClass: -1, //区高亮
        }
    },
      mounted() {
    this.storeList();
    this.getShopList(); //获取商铺产品列表
    this.getShopListContent(); //获取商铺产品列表
    this.getAdressClass(); //获取区数据

  },
  created(){
    // this.activeName = "推荐";
    console.log(this.$route.query.val)
  },
  methods: {



    selectClass(adcode,index){ //地区分类选择
      this.param.adcode=adcode;
      this.getShopListContent();
      this.activeClass = index;
    },
    getAdressClass(){ //地区分类数据获取
      this.$http.get('/area/getAreaByCityName?name='+this.cityName).then(res => {
        this.AdressClassList = res.data.data;
        // console.log(res.data.data,77)
      })
    },
    //以下一键支付下单
    checkedAll(e){ //选中协议
      if (!e) {
        this.isDisable=true;
        this.isSave=false;
      }
      if(e){

        this.isDisable=false;
        this.isSave=true;
      }
    },

    oneKeyShow(storeName,storeId){ //点击一键支付时，保存店铺信息
      this.fastList={
        "storeName":storeName,
        "storeId":storeId,
      }
    },
    onSubmit1(){ //一键支付
      if (this.form.price=="") {
       this.$message.error('请输入订单金额!');
       return;
     }else{
        var reg= /^-?\d+$/;
        if(!reg.test(this.form.price)){
            this.$message.error('输入金额需为纯数字!');
            return;
        }
    }
        this.$router.push({
          name:"order",
        })
          this.fastList.info=this.form.info; //需求信息
          this.fastList.price=this.form.price;//一键支付输入的价格
          this.fastList.fastFlag="2";
          this.fastList.img="upload/b060a4a7810743fa8ea8cdc56d124138.png";
          this.fastList.name="线下业务单";
          this.fastList.sn="";
          localStorage.setItem("fastList",JSON.stringify(this.fastList));
    },






     productShow(img,name,price,id,storeId){
       this.productObj.img=img;
       this.productObj.name=name;
       this.productObj.price=price;
       this.productObj.id=id;
        this.productObj.storeId=storeId;
     },
      onSubmit(){ //快速下单提交
      if (this.form.price=="") {
       this.$message.error('请输入订单金额!');
       return;
     }else{
        var reg= /^-?\d+$/;
        // console.log(this.form.price,77777777777777)
        if(!reg.test(this.form.price)){
            this.$message.error('输入金额需为纯数字!');
            return;
        }
    }

        this.$router.push({
          name:"order",
        })
        let fastList ={
          name:this.productObj.name,
          storeId:this.productObj.storeId,
           id:this.productObj.id,
           img:this.productObj.img,
            info:this.form.info,
            price:this.form.price,
           fastFlag:"0"
          }
          localStorage.setItem("fastList",JSON.stringify(fastList));
     },
    phone(storeId){ //联系商家
      this.$http.get('store/info', { params: { storeId: storeId } }).then(res => {
        this.store = res.data.data;
      })
    },
    goshop(storeId){ //首页列表进店铺
      this.$router.push({
        name:"shopPage",
        params:{
          id:storeId
        }
      })
    },

    getShopListContent(){ //分类搜索列表页展示内容
       this.$http.post('/store/product/name',this.param).then(res=>{
        this.ShopListContent=res.data.data.list;
      })
    },

    getShopList(){ //
      this.$http.get('pic/adv/config/new/list?code=pc_sy_fltj').then(res=>{
        this.recommendList=res.data.data;
      })
    },

    storeList() {

    },


    },
  }

</script>

<style lang="scss">
*{
    margin:0;
    padding:0;
}
.classSearchWrap{
  background: #f5f5f5;
}
.titles{
      display: flex;
      justify-content: flex-start;
      border-bottom: 1px solid  #eee;
      margin-bottom: 20px;
      height: 48px;
      :nth-child(1){
        margin-right: 20px;
        line-height: 20px;
        font-size: 20px;
        color: #333;
        font-weight: 900;
      }
      :nth-child(2){
        font-size: 16px;
        color:#333;
      }
    }
li{
    list-style: none;
}
em{
  font-style: normal;
}
.classWrap{
  // margin-bottom: 20px;
}
    .adress{
        width: 1200px;
        margin:0 auto;
        // background: red;
          .content-top{
          margin-bottom: 10px;
          background: #fff;
        ul{
          border-bottom: 1px solid #eee;
           li{
              width: 100%;
              list-style: none;
              line-height: 45px;
              font-size: 16px;
              border-bottom: 1px dashed #e5e5e5;
              color: #333;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              em{
                height: 35px;
                margin-right: 30px;
                line-height: 35px;
              }
              >div{
                max-width:1050px;
                display: flex;
                flex-wrap: wrap;
                span{
                  margin-right: 30px;
                }
                .active{
                  color: #d82229;
                }
              }
            }
            li:last-child{
              border: none;
            }
        }
        .sort{
               width: 100%;
              // min-height: 51px;
              line-height: 45px;
              font-size: 16px;
              color: #333;
              border-left: none;
              border-right: none;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-wrap: wrap;
              em{
                margin-right: 30px;
              }
              span{
                margin-right: 30px;
              }
            }

          }
    }








     .section{
         width: 1200px;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          flex-direction: column;

          .el-tabs__item.is-active {
            color: #d82229;
          }

          .el-tabs__active-bar {
            height: 4px;
            background-color: #d82229;
          }

          .el-tabs__nav > div {
            &:hover {
              color: #d82229 !important;
            }
          }

          .el-tabs__item {
            font-size: 18px;
            color: #333;


          }

          .el-tabs__nav {
            height: 60px;
            line-height: 60px;
            margin-top: 5px;
          }

          .el-tabs__content {
            display: block;
          }
          .is-top{
            margin-bottom: 0px;
          }


          .recommend-list-wrap{
            margin-top: 15px;
            .recommend-list{ //大列表
               padding: 0 20px;
               height: 320px;
               background: #fff;
               margin-bottom: 20px;
               .shopAd{ //广告公司信息
                  height: 110px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  border-bottom: 1px solid #eee;
                  .shopAd-content{ //左侧主体
                      width: 720px;
                      height: 70px;
                      display: flex;
                      justify-content: flex-start;
                      .shopAd-content-left{
                        margin-right: 15px;
                        img{
                          display: block;
                          width:70px;
                          height: 70px;
                        }
                      }
                      .shopAd-content-right{

                        .opens{
                          height: 30px;
                          display: flex;
                          justify-content: flex-start;
                          align-items: center;
                          span{
                            margin-right: 8px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          }
                          :nth-child(1){
                            font-size: 18px;
                            color: #333;
                            font-weight: 900;
                          }
                          :nth-child(2){
                            width: 20px;
                            height: 20px;
                            text-align: center;
                            line-height: 20px;
                            font-size: 14px;
                            color: #fff;
                            background: #ffac00;
                            border-radius: 2px;
                            // padding:2px;
                          }
                          :nth-child(5){
                            margin-right:6px;
                            font-size: 14px;
                            color: #666;
                            margin-right: 0;
                          }
                          :nth-child(6){
                            font-size: 14px;
                            margin: 0 10px;
                            color: #666;
                          }
                          :nth-child(8),:nth-child(9),:nth-child(10){
                            font-size: 14px;
                            color: #666;
                            margin-right: 12px;
                          }
                          .shopAd-btn{ //右侧进入店铺按钮
                            outline: none;
                            width: 68px;
                            height: 24px;
                            font-size: 14px;
                            color:#ffa219;
                            border-radius: 3px;
                            background: none;
                            border: 1px solid #ffa219;
                            cursor: pointer;
                            margin-right: 70px;
                          }
                        }
                        .server{
                          margin-top:10px;
                          height: 30px;
                          // background: orange;
                          display: flex;
                          justify-content: flex-start;
                          align-items: center;
                          .server-title{
                              font-size: 14px;
                              color: #666;
                              margin-right: 12px;
                          }
                          .server-list{
                              font-size: 12px;
                              color:#999;
                              span{
                                padding: 5px 6px;
                                margin-right: 14px;
                                border: 1px solid #e5e5e5;
                                border-radius: 2px;
                              }
                              :last-child{
                                margin-right: 0;
                              }
                          }
                        }

                      }

                  }
                  .productBtnWrap{
                  width: 150px;
                  height: 110px;
                  padding-left: 40px;
                  border-left: 1px solid #eeeeee;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  margin-right: 35px;
                  padding: 15px 0 15px 40px;
                  .shopAd-btn{ //右侧进入店铺按钮
                    margin-left: auto;
                    outline: none;
                    width: 108px;
                    height: 34px;
                    font-size: 16px;
                    color:#fff;
                    border-radius: 3px;
                    background: none;
                    cursor: pointer;
                    margin-right: 70px;
                    border:1px solid #d82229;
                    color: #d82229;
                    // border: none;
                  }
                  :nth-child(2){
                    background: #d82229;
                    color: #fff;
                  }
                }
              }
              .otherwrap{
                line-height: 50px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: 14px;
                color: #999;
                span{
                  &:hover{
                  color: #ff0a51;
                  cursor: pointer;
                }
                }
              }
            }
            .recommend-list2{
              height: 280px;
            }
            >:last-child{
              margin-bottom: 0;
            }

            .productWrap{
              .productWrap-top{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 20px;
                .productWrap-top-content{
                  display: flex;
                  dl{
                    width: 288px;
                    height: 140px;
                    display: flex;
                    margin-right: 20px;
                    justify-content: space-between;
                    dt{
                      width: 140px;
                      height: 140px;
                      overflow: hidden;
                      flex-shrink: 0;
                      img{
                        display: block;
                        width: 100%;
                        height: 100%;
                      }
                    }
                    dd{
                      width: 139px;
                      height: 100px;
                      font-size: 16px;
                      color: #333;
                      flex-shrink: 0;
                      >:nth-child(1){
                        margin-top: 11px;
                        height: 44px;
                        text-align: left;

                      }
                      >:nth-child(1),>:nth-child(2){
                        text-align: left;
                        line-height:22px;
                        color: #333;
                        font-size: 16px;
                        word-break: break-all;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                      }
                      >:nth-child(2){
                        line-height: 34px;
                          >:nth-child(1){
                            color: #ff0a50;
                            font-weight: 600;
                          }
                      }
                      >:nth-child(3){
                        width: 110px;
                        height: 34px;
                        outline: none;
                        background: none;
                        border-radius: 3px;
                        border: 1px solid #333;
                        text-align: center;
                        line-height: 34px;
                        color:#333;
                        font-size: 14px;
                        cursor: pointer;
                        margin-top: 3px;
                      }
                    }
                  }
                }
                .productBtnWrap{
                  width: 150px;
                  height: 110px;
                  padding-left: 40px;
                  border-left: 1px solid #eeeeee;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  margin-right: 70px;
                  .shopAd-btn{ //右侧进入店铺按钮
                    margin-left: auto;
                    outline: none;
                    width: 108px;
                    height: 34px;
                    font-size: 16px;
                    color:#fff;
                    border-radius: 3px;
                    background: none;
                    cursor: pointer;
                    margin-right: 70px;
                    border:1px solid #d82229;
                    color: #d82229;
                    // border: none;
                  }
                  :nth-child(2){
                    background: #d82229;
                    color: #fff;
                  }
                }
              }

              .productWrapBtn{
                outline: none;
                    width: 108px;
                    height: 34px;
                    font-size: 16px;
                    color:#ffa219;
                    border-radius: 3px;
                    background: none;
                    border: 1px solid #ffa219;
                    cursor: pointer;
                    margin-right: 70px;
              }
              .oneKeywrap{
                font-size: 18px;
                .oneKey{
                    color: #ffac00;
                    font-size: 18px;
                 }
              }

            }
          }
          >:nth-child(1){
            margin-top: 0;
          }

    }





 //以下弹窗样式
 .quickOrder{
/deep/ .el-dialog{
  width:440px;
  height:220px;
  margin-top:40vh !important;
  .el-dialog__body{
    padding:0;
  }

  .el-dialog__header{
      background: #f5f5f5;
  }
  .popTop{
    height:120px;
    .storeName{
      text-align: center;
      font-size:22px;
      color:#333;
      height:44px;
      line-height:44px;
      background: #f5f5f5;
    }
    .storeMobile{
      height:66px;
      line-height: 66px;
      font-size:28px;
      color:#333;
     background: #f5f5f5;
      >div{
        width:280px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin:0 auto;
        padding:0 10px;
        overflow: hidden;
        color:#333333;
        img{
          display: block;
        }
      }
      .streFooter{
        text-align: center;
        height: 80px;
        font-size: 12px;
        color: #999;
        display: flex;
        justify-content: center;
        line-height: 24px;
      }
    }
  }

}
 }

.quickOrder1{
  /deep/ .el-dialog{
      width:720px;
      padding-bottom: 50px;
    //  height: 510px !important;
     .titles{
       font-size: 16px;
       :last-child{
         color: #d82229;
       }
     }

    /deep/ .productPop{
            display: flex;
            height: 150px;
            margin: 20px 0;

            >:nth-child(1){
              width: 80px;
              padding-right: 12px;
              flex-shrink: 0;
              text-align:right;
            }
            >:nth-child(2){
              width: 150px;
              margin-right:20px;
              flex-shrink: 0;
              width: 150px;
              height: 150px;
              img{
                display: block;
                width: 100%;
                height: 100%;
              }
            }
            >:nth-child(3){
              width: 390px;
              p{

                font-size: 16px;
                line-height: 40px;
                text-align: left;
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              >div{
                line-height: 46px;
                text-align: left;

                :nth-child(1){
                  font-size: 14px;
                }
                 :nth-child(2){
                  font-size: 16px;
                  color:#d82229;
                }
                :nth-child(3){
                  font-size: 14px;
                }
              }
            }

          }
   }
    /deep/ .el-dialog .el-dialog__body .el-form-item .el-form-item__label{
      width: 80px !important;
      flex-shrink: 0;
      padding-right:10px;
      // padding: 0;
  }
 }

.quickOrder2{
   .el-form-item__content{
     width:100%;
   }
   .titles{
       font-size: 16px;
       :last-child{
         color: #d82229;
       }
     }
   .el-textarea{
     width:100%;
     height:180px;
   }
   .el-textarea__inner{
     width: 100%;
     height:180px;
     resize:none;
   }
   .el-dialog{
     height:auto;
     padding-bottom:40px;
   }
 }

 .el-button--primary{
   width: 100%;
   height:100%;
   background: #d82229;
   border: none;
 }


.el-dialog{
  width: 720px;
  .el-dialog__body{
    padding: 0 50px;
    .titles{
      display: flex;
      justify-content: flex-start;
      border-bottom: 1px solid  #eee;
      margin-bottom: 20px;
      height: 48px;
      :nth-child(1){
        margin-right: 20px;
        line-height: 20px;
        font-size: 20px;
        color: #333;
        font-weight: 900;
      }
      :nth-child(2){
        font-size: 16px;
        color:#333;
      }
    }

    .el-form-item{
        display: flex;
        justify-content: flex-start;
        margin-bottom: 20px;
         .el-form-item__label{
            width: 82px !important;
            flex-shrink: 0;
            font-size: 14px;
            color: #333;
          }
          .el-input__inner{
            width: 540px;
          }
      }
      .protocol{
        display: flex;
        margin-bottom: 30px;
        margin-left: 79px;
        .protocol-word{
          color: #d82229;
        }
      }
      .el-checkbox__input.is-checked + .el-checkbox__label{
        color:#999;
      }
      .is-disabled{
        width: 100%;
        height:100%;
      }
      .el-checkbox{
        color: #999;
      }
      .save{
        display: flex;

        .el-form-item__content{
          width: 540px;
          height: 45px;
          color: #fff;
          font-size: 16px;
          background: #d82229;
          // margin: 0 auto;
          border-radius: 4px;
          margin-left: auto;
          cursor: pointer;
        }
      }
  }
  .is-disabled{
      background: #d82229;
      border: none;
      opacity: .5;
  }
  .el-button--primary{
    width: 100%;
    height:100%;
    background: #d82229;
    border:none;
    // color: #fff;
  }
}

</style>
